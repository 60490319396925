<template>
  <b-modal
    hide-footer
    id="loginModal"
    v-model="loginModalIsOpen"
    modal-class="modal-primary"
    centered
    title="Окно Входа"
  >
    <validation-observer ref="loginForm" #default="{invalid}">
      <b-form class="auth-login-form mt-2" @submit.prevent="login">
        <!-- email -->
        <b-form-group label="Почта" label-for="login-email">
          <validation-provider
            #default="{ errors }"
            name="Email"
            vid="email"
            rules="required|email"
          >
            <b-form-input
              id="login-email"
              v-model="userEmail"
              :state="errors.length > 0 ? false : null"
              name="login-email"
              placeholder="admin@example.com"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- forgot password -->
        <b-form-group label="Пароль" label-for="login-password">
          <validation-provider
            #default="{ errors }"
            name="Password"
            vid="password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge custom_input"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="login-password"
                v-model="password"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
                placeholder="Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- submit buttons -->
        <b-button type="submit" variant="primary" block :disabled="invalid">
          Войти
        </b-button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BFormInput,
  BModal,
  BButton,
  BForm,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'LoginModal',
  components: {
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BModal,
    BFormInput,
    BButton,
    BForm,
  },
  data() {
    return {
      required,
      email,
      userEmail: '',
      password: '',
      passwordFieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    loginModalIsOpen: {
      get() {
        return this.$store.state.loginModal.loginModalIsOpen
      },
      set(value) {
        this.$store.dispatch('loginModal/setloginModalIsOpen', value)
      },
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then(response => {
              let userData = response.data.user
              if (userData.role === 'admin') {
                userData.ability = [
                  {
                    action: 'manage',
                    subject: 'all',
                  },
                ]
              } else {
                userData.ability = []
              }
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)

              if (userData) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Добро пожаловать ${userData.fullName ||
                      userData.username}`,
                    icon: 'CoffeeIcon',
                    variant: 'Успешно',
                    text: `Вы успешно вошли в систему`,
                  },
                })
              } else {
                this.$router.push({ name: 'auth-login' })
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Ошибка`,
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text:
                      error.response.data.message || 'Попробуйте войти снова',
                  },
                })
              }
              this.$store.dispatch('loginModal/setloginModalIsOpen', false)
            })
        }
      })
    },
  },
}
</script>

<style lang="sass" scoped></style>
